import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import IdcardModal from "./IdcardModal";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "./Pagination.css";

const AnggotaList = () => {
  const [showModal, setShowModal] = useState(false);
  let [idCard, setIdCard] = useState("");
  const [member, setMember] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [qyuery, setQuery] = useState("");

  useEffect(() => {
    const fetcher = async () => {
      const response = await axios.get(
        `https://sujarta.datapratama.net/members?search_query=${keyword}&page=${page}&limit=${limit}`
      );
      setMember(response.data.result);
      setPage(response.data.page);
      setLimit(response.data.limit);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    fetcher();
  }, [page, keyword]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setKeyword(qyuery);
  };

  return (
    <Fragment>
      <div className="container mt-5">
        <div className="columns">
          <div className="flex-col mt-5 w-full">
            <div className="flex items-center">
              <form onSubmit={searchData}>
                <div className="flex space-x-1">
                  <input
                    type="text"
                    value={qyuery}
                    onChange={(e) => setQuery(e.target.value)}
                    className="block w-full px-4 py-2 text-purple-700 bg-white border rounded-full focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Pencarian data ...."
                  />
                  <button className="px-4 text-white bg-purple-600 rounded-full ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="w-full">
              <div className="relative shadow rounded-lg mt-3">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th className="py-3 px-1 text-center">No</th>
                      <th className="py-3 px-6">Id Anggota</th>
                      <th className="py-3 px-6">Nama Anggota</th>
                      <th className="py-3 px-1 text-center">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {member.map((anggota, index) => (
                      <tr className="bg-white border-b key={angota.id}">
                        <td className="py-3 px-1 text-center">{index + 1}</td>
                        <td className="py-3 px-6">{anggota.idcard}</td>
                        <td className="py-3 px-6  font-medium text-gray-900">
                          {anggota.nama}
                        </td>
                        <td className="py-3 px-1 text-center">
                          <button
                            className=" font-medium bg-blue-400 hover:bg-blue-500 px-3 py-1 rounded text-white mr-1"
                            onClick={() => {
                              setShowModal(true);
                              setIdCard("./cards/" + anggota.idcard + ".png");
                            }}
                          >
                            VIRTUAL CARD
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p>
                Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
              </p>
              <nav
                key={rows}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 20,
                  boxSizing: "border-box",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ReactPaginate
                  activeClassName={"item active "}
                  breakClassName={"item break-me "}
                  breakLabel={"..."}
                  containerClassName={"pagination"}
                  disabledClassName={"disabled-page"}
                  marginPagesDisplayed={2}
                  nextClassName={"item next "}
                  nextLabel={
                    <ArrowForwardIosIcon style={{ fontSize: 18, width: 150 }} />
                  }
                  pageCount={pages}
                  onPageChange={changePage}
                  pageClassName={"item pagination-page "}
                  pageRangeDisplayed={2}
                  previousClassName={"item previous"}
                  previousLabel={
                    <ArrowBackIosIcon style={{ fontSize: 18, width: 150 }} />
                  }
                />
              </nav>
            </div>
            <IdcardModal
              isvisible={showModal}
              onClose={() => setShowModal(false)}
              dataIdCard={idCard}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AnggotaList;
