import React from "react";

const IdcardModal = ({ isvisible, onClose, dataIdCard }) => {
  if (!isvisible) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
      <div className="w-[321px] flex flex-col">
        <button
          className="text-white text-xl place-self-end"
          onClick={() => onClose()}
        >
          X
        </button>
        <div className="bg-white p-2 rounded">
          <img src={dataIdCard} width={321} height={207} alt="VCARD" />
        </div>
      </div>
    </div>
  );
};

export default IdcardModal;
